<template>
  <div>
    <template v-if="tourRemoteGone">
      <b-alert variant="danger" show>
        <h2>Tour not found on server!</h2>
        <p>
          This tour does not exist anymore on the server but only on your device. If you feel this is an error, please contact your Team.
          If the tour has been caneled or handed over to another guide, you can just delete it from your device.
          Please note that any unsaved vouchers will be will also be deleted.
        </p>
        <b-button variant="danger" @click="deleteTourHandler" :disabled="tourLoading">
          Delete Tour
        </b-button>
      </b-alert>
    </template>
    <template v-else-if="!tourStaff">
      <b-alert variant="danger" show v-if="tourStaffStatus && tourStaffStatus.canceled_at">
        <h2>Tour staff cancled!</h2>
        <p>
          Your staff assignment has been canceled for this tour!
          If you have any questions, please contact your Team.
        </p>
        <b-button variant="danger" @click="deleteTourHandler" :disabled="tourLoading">
          Delete Tour
        </b-button>
      </b-alert>
      <b-alert variant="danger" show v-else-if="tour.staff.length > 0">
        <h2>Tour staff not found!</h2>
        <p>
          Your staff assignment has not been found in this tour. Maybe your tour has been canceled or you received an invalid Tour Link.
          If you have any questions, please contact your Team.
        </p>
        <b-button variant="danger" @click="deleteTourHandler" :disabled="tourLoading">
          Delete Tour
        </b-button>
      </b-alert>
    </template>
    <template v-else>
      <div v-if="tour.status == 'canceled' || tourStaff.status == 'canceled'">
        <b-alert variant="danger" show>
          <h2>Tour canceled!</h2>
          <p>This tour has been canceled. If you have any questions, please contact your Team.</p>
          <b-button variant="danger" @click="deleteTourHandler" :disabled="tourLoading">
            Delete Tour
          </b-button>
        </b-alert>
      </div>
      <div v-else-if="!tourStaff.confirmed_at">
        <b-alert variant="danger" show>
          <h2>Tour not confirmed!</h2>
          <p>This tour has not been confirmed. If you have any questions, please contact your Team.</p>
          <b-button variant="danger" @click="deleteTourHandler" :disabled="tourLoading">
            Delete Tour
          </b-button>
        </b-alert>
      </div>
      <div v-else-if="!tourStaff.accepted_at">
        <b-alert variant="warning" show>
          <h2>Tour not accepted!</h2>
          <p>This tour has not been accepted yet. Please let your Team know that you will accept this tour.</p>
          <b-button variant="success" @click="acceptTour" :disabled="tourLoading">
            <b-spinner v-if="tourLoading" label="Accepting" small></b-spinner>
            Accept Tour
          </b-button>
        </b-alert>
      </div>
      <b-alert variant="danger" :show="Object.keys(tourStaff).length === 0">
        <h2>Tour staff missing!</h2>
        The tour must have at least one Guide or Escort.
      </b-alert>
    </template>

    <b-alert variant="info" show dismissible v-if="tourChanged" @dismissed="clearChangedFields">
      <h2>Tour changed!</h2>
      <p>The following fields of this tour recently changed:</p>
      <div>
        <div v-for="field in Object.keys(tourChangesFlat)" :key="field">
          <wrapped-text :label="field" :text="getTourValue(field)" />
        </div>
      </div>
      <b-button-toolbar class="mt-2">
        <b-button variant="primary" @click="clearChangedFields" size="sm" class="mr-2">
          Dismiss
        </b-button>
        <b-button v-b-toggle.tour-changes-debug variant="outline-primary" size="sm" class="mr-2" v-if="$dev">
          Debug
        </b-button>
      </b-button-toolbar>
      <b-collapse id="tour-changes-debug" class="mt-4">
        <pre>{{tourChanges}}</pre>
      </b-collapse>
    </b-alert>
  </div>
</template>

<script lang="ts">
import {namespace} from 'vuex-class'
import { Vue, Component, Prop } from 'vue-property-decorator'
import {TourItem} from "@/apps/guide/store/tours/types";
import _ from 'lodash'
import Guide from "@/apps/guide/guide";
const tour = namespace('tour')
const tours = namespace('tours')
@Component
export default class TourComponent extends Vue {
  @tour.Getter tour: TourItem
  @tour.Getter tourStaff
  @tour.Getter tourStaffStatus
  @tour.Getter tourLoading
  @tour.Getter tourRemoteGone
  @tour.Getter tourChanged
  @tour.Getter tourChanges
  @tour.Getter tourChangesFlat
  @tour.Getter tourChangedFields
  @tour.Mutation clearChangedFields
  @tour.Action acceptTour
  @tours.Action deleteTour

  getTourValue(key){
    return _.get(this.tour, key)
  }

  async deleteTourHandler(){
    const tour = this.tour
    Guide.navigate({name: 'home'}, 'Tour deleted!', 'info')
    await this.deleteTour(tour)
  }
}
</script>