import Api from './api'
import Storage from './storage'
import Sync from './sync'
import { registerSW } from 'virtual:pwa-register'
import store from './store'
import router from './router'
import Notify from '../misc/notify'

const Guide = {
  debug: process.env.NODE_ENV !== 'production' || localStorage.eguideDebug,
  store: store,
  router: router,
  api: Api,
  storage: new Storage(),
  notify: new Notify(store),
  sync: Sync,
  //rollbar: new Rollbar(ROLLBAR_CONFIG),
  serviceWorkerDev: false,
  updateServiceWorker: null,
  get serviceWorkerUrl(){
    if(process.env.NODE_ENV == 'development' && this.serviceWorkerDev){
      return process.env.BASE_URL + 'service-worker.dev.js'
    } else {
      return process.env.BASE_URL + 'service-worker.js'
    }
  },
  navigate(route, message?, notification = "info"){
    if(message) {
      if(this.notify[notification]) this.notify[notification](message)
      else this.notify.info(message)
    }
    this.router.push(route)
  },
  async reloadApp(){
    console.log("Reloading app")
    if(this.updateServiceWorker){
      console.log("Updating service worker")
      await this.updateServiceWorker()
    }
    setTimeout(()=>{
      console.log("Reloading page")
      window.location.reload()
    }, 1000)
  }
}

Guide.updateServiceWorker = registerSW({
  immediate: true,
  onNeedRefresh() {
    console.log("SW: refresh needed")
    Guide.store.commit('setUpdateAvailable', true)
  },
  onOfflineReady() {
    console.log("SW: offline ready")
  },
  onRegisteredSW(){
    console.log("SW: registered")
  },
  onRegisterError(error){
    console.log("SW: registration error", error)
  }
})

// watch state changes
store.subscribe(async (mutation, state) => {
  const payload = mutation.payload
  switch(mutation.type){
    case 'tours/addTour':
    case 'tours/replaceTour':
      await Guide.storage.persistTour(payload)
      break
    case 'tours/removeTour':
      await Guide.storage.deleteTour(payload.id)
      break
  }
})


window.ononline = async (e) => {
  store.commit('setOnline', true)
  Guide.sync.run()
}

window.onoffline = (e) => {
  store.commit('setOnline', false)
}


window.onblur = function() {
  Guide.sync.run()
}

window.onfocus = function() {
  if(!localStorage.eguideAutoReloadAt) {
    localStorage.eguideAutoReloadAt = new Date().toISOString()
  } else {
    const lastReload = new Date(localStorage.eguideAutoReloadAt)
    const now = new Date()
    if(now.toDateString() != lastReload.toDateString()) {
      localStorage.eguideAutoReloadAt = new Date().toISOString()
      window.location.reload()
      return
    }
  }
  Guide.sync.run()
}


export default Guide
