<template>
  <div>
    <p>This will send an e-mail to your Team with your tour feedback.</p>
    <b-form-group>
      <b-textarea
        :disabled="isTransmitting"
        style="min-height:30vh"
        placeholder="Enter at least 30 characters"
        :value="getText"
        @input="setText($event)"
        :state="validInput"
      ></b-textarea>
    </b-form-group>
    <b-form-group class="text-right">
      <span>
        <b-spinner v-if="isTransmitting" class="text-success mr-2" small></b-spinner>
        <fa v-if="isTransmittingError" icon="times" class="text-danger mr-2"></fa>
      </span>
      <b-button v-if="!isTransmitting" :disabled="!validInput" @click="sendTourFeedback">
        <fa icon="paper-plane"></fa>
        Send
      </b-button>
    </b-form-group>
  </div>
</template>

<script lang="ts">
  import {Vue, Component} from 'vue-property-decorator'
  import {namespace} from "vuex-class"

  const feedback = namespace('tour/feedback')
  @Component
  export default class TourFeedback extends Vue{
    @feedback.Action sendTourFeedback
    @feedback.Getter isTransmitting
    @feedback.Getter isTransmittingError
    @feedback.Getter getText
    @feedback.Mutation setText

    get validInput() {
      return this.getText.length >= 30
    }
  }
</script>
